var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-50 mb-md-1 border-info-2",
    attrs: {
      "body-class": "p-50"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-md-row justify-content-md-between mb-1"
  }, [_c('div', {
    staticClass: "bg-blue-gradient d-flex align-items-center mb-25 mb-md-0"
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-alert', {
    staticClass: "p-75 p-md-1 mr-25 mr-md-75 mb-0",
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "show": "",
      "variant": "primary"
    }
  }, [_c('feather-icon', {
    staticClass: "text-body",
    attrs: {
      "icon": "BriefcaseIcon",
      "size": _vm.isMobileView ? '20' : '30'
    }
  })], 1)], 1), _c('div', [_c('div', {
    class: "text-airline fw-700 mb-0 mb-md-25 ".concat(_vm.isMobileView ? 'font-medium-4' : 'font-medium-3')
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.addonAncillary')) + " "), _vm.isMobileView ? _c('feather-icon', {
    staticClass: "text-info cursor-pointer",
    attrs: {
      "id": "header-tooltip-mobile-addons",
      "icon": "InfoIcon",
      "size": "16"
    }
  }) : _vm._e()], 1), _vm.isMobileView ? _c('b-tooltip', {
    attrs: {
      "target": "header-tooltip-mobile-addons",
      "triggers": "hover focus",
      "boundary": "window",
      "variant": "info"
    }
  }, [_c('small', [_vm._v(" " + _vm._s(_vm.$t('flight.detailAddonAncillary')))])]) : _vm._e(), !_vm.isMobileView ? _c('span', {
    staticClass: "fw-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.detailAddonAncillary')) + " ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "d-flex-center d-flex-md-between gap-2"
  }, [_vm._l(_vm.buttons, function (button) {
    return [_vm.sourcesCanBuyAddons(button.key) && !_vm.isHideFunction ? _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.300",
        value: 'rgba(255, 159, 67, 0.15)',
        expression: "'rgba(255, 159, 67, 0.15)'",
        modifiers: {
          "300": true
        }
      }],
      key: button.key,
      staticClass: "p-50 px-md-1 rounded-lg",
      attrs: {
        "variant": _vm.currentView === button.key ? 'primary' : 'outline-primary',
        "disabled": false
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickTab(button);
        }
      }
    }, [_c('span', {
      staticClass: "text-nowrap",
      domProps: {
        "innerHTML": _vm._s("".concat(_vm.$t("flight.".concat(button.label))))
      }
    })]) : _vm._e()];
  })], 2)]), _c('keep-alive', [_c(_vm.currentView, _vm._b({
    tag: "component"
  }, 'component', _vm.getPropsForCurrentView, false))], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }