<template>
  <b-card
    body-class="p-50"
    class="mb-50 mb-md-1 border-info-2"
  >
    <div class="d-flex flex-column flex-md-row justify-content-md-between mb-1">
      <div class="bg-blue-gradient d-flex align-items-center mb-25 mb-md-0">
        <div class="d-flex-center">
          <b-alert
            show
            variant="primary"
            class="p-75 p-md-1 mr-25 mr-md-75 mb-0"
            style="width: fit-content"
          >
            <feather-icon
              icon="BriefcaseIcon"
              :size="isMobileView ? '20' : '30'"
              class="text-body"
            />
          </b-alert>
        </div>

        <div>
          <div :class="`text-airline fw-700 mb-0 mb-md-25 ${isMobileView ? 'font-medium-4' : 'font-medium-3'}`">
            {{ $t('flight.addonAncillary') }}
            <feather-icon
              v-if="isMobileView"
              id="header-tooltip-mobile-addons"
              icon="InfoIcon"
              size="16"
              class="text-info cursor-pointer"
            />
          </div>

          <b-tooltip
            v-if="isMobileView"
            target="header-tooltip-mobile-addons"
            triggers="hover focus"
            boundary="window"
            variant="info"
          >
            <small> {{ $t('flight.detailAddonAncillary') }}</small>
          </b-tooltip>

          <span
            v-if="!isMobileView"
            class="fw-600"
          >
            {{ $t('flight.detailAddonAncillary') }}
          </span>
        </div>
      </div>

      <!-- ANCHOR - BUTTON -->
      <div class="d-flex-center d-flex-md-between gap-2">
        <template v-for="button of buttons">
          <b-button
            v-if="sourcesCanBuyAddons(button.key) && !isHideFunction"
            :key="button.key"
            v-ripple.300="'rgba(255, 159, 67, 0.15)'"
            :variant="currentView === button.key ? 'primary' : 'outline-primary'"
            :disabled="false"
            class="p-50 px-md-1 rounded-lg"
            @click="handleClickTab(button)"
          >
            <span
              class="text-nowrap"
              v-html="`${$t(`flight.${button.label}`)}`"
            />
          </b-button>
        </template>
      </div>
    </div>

    <keep-alive>
      <component
        :is="currentView"
        v-bind="getPropsForCurrentView"
      />
    </keep-alive>
  </b-card>
</template>

<script>
import {
  BCard,
  BAlert,
  BButton,
  BTooltip,
  VBTooltip,
} from 'bootstrap-vue'
import {
  ref,
  toRefs,
  computed,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'

import useCreateBookingHandle from '@flightv2/reservation/useCreateBookingHandle'

export default {
  components: {
    BTooltip,
    BCard,
    BButton,
    BAlert,

    BaggageComponent: () => import('./baggage/index.vue'),
    OtherComponent: () => import('./other/index.vue'),
    SeatComponent: () => import('./seat/index.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    ssrBags: {
      type: Array,
      default: () => [],
    },
    ssrOthers: {
      type: Array,
      default: () => [],
    },
    seatMapData: {
      type: Array,
      default: () => [],
    },
    selectedTrip: {
      type: Array,
      default: () => [],
    },
    passengerDataToAdd: {
      type: Array,
      default: () => [],
    },
    isCombination: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      ssrBags,
      ssrOthers,
      seatMapData,
      selectedTrip,
      passengerDataToAdd,
      isCombination,
    } = toRefs(props)

    const {
      sourcesCanBuyAncillary,
      sourcesCanBuySeatMap,
      isHideFunction,
    } = useCreateBookingHandle()

    const buttons = [
      {
        label: 'addBaggage',
        text: 'HÀNH LÝ',
        key: 'BaggageComponent',
      },
      {
        label: 'addSeat',
        text: 'CHỖ NGỒI',
        key: 'SeatComponent',
      },
      {
        label: 'addOtherServices',
        text: 'DỊCH VỤ KHÁC',
        key: 'OtherComponent',
      },
    ]

    const currentView = ref('BaggageComponent')

    const getPropsForCurrentView = computed(() => {
      if (['BaggageComponent'].includes(currentView.value)) {
        return {
          ssrBags: ssrBags.value,
          selectedTrip: selectedTrip.value,
          passengerDataToAdd: passengerDataToAdd.value,
          isCombination: isCombination.value,
        }
      }
      if (currentView.value === 'SeatComponent') {
        return {
          seatMapData: seatMapData.value,
          selectedTrip: selectedTrip.value,
          passengerDataToAdd: passengerDataToAdd.value,
          isCombination: isCombination.value,
        }
      }
      if (['OtherComponent'].includes(currentView.value)) {
        return {
          ssrOthers: ssrOthers.value,
          selectedTrip: selectedTrip.value,
          passengerDataToAdd: passengerDataToAdd.value,
          isCombination: isCombination.value,
        }
      }
      return {}
    })

    function sourcesCanBuyAddons(key) {
      if (['BaggageComponent', 'OtherComponent'].includes(key)) {
        return selectedTrip.value.some(trip => sourcesCanBuyAncillary.value.includes(trip.source))
      }
      if (['SeatComponent'].includes(key)) {
        return selectedTrip.value.some(trip => sourcesCanBuySeatMap.value.includes(trip.source))
      }
      return false
    }

    const textView = ref('HÀNH LÝ')

    function handleClickTab(btn) {
      currentView.value = btn.key
      textView.value = btn.text
    }

    return {
      buttons,
      currentView,
      textView,
      getPropsForCurrentView,
      handleClickTab,
      isHideFunction,
      sourcesCanBuyAddons,
    }
  },
}
</script>

<style scoped>
</style>
